.main-body-container{
    height: 78vh;
    width: 100%;
    position: relative;
}

.auth-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.data-screen-container{
    min-height: 78vh;
}

.icon-container{
    height: 10vh;
    width: 10vh;
    border-radius: 50%;
    background-color: #c7ecff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.css-oy8i6b-MuiPaper-root-MuiAccordion-root:first-of-type,
.css-1lm0vn-MuiPaper-root-MuiAccordion-root:last-of-type {
    border-top-left-radius: 15px !important; 
    border-bottom-left-radius: 15px !important; 
    border-top-right-radius: 15px !important; 
    border-bottom-right-radius: 15px !important; 
}