.header-container{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    background: #c7ecff;
    position: relative;
}

.elecon-logo{
    height: 10vh;
    width: 50%;
}

.footer-container{
    height: 5vh;
    width: 100%;
    background: #c7ecff;
    display: flex;
    justify-content: center;
    align-items: center;
}